<template>
  <div v-if="carregandoPagina" class="col-md-12" style="text-align: center;padding-top: 255px;">
    <pm-ProgressSpinner />
  </div>
  <section v-if="!carregandoPagina">

    <!--<pm-Breadcrumb :home="home" :model="items_bread" />-->

    <div class="row">
      <div class="col-md-7">
        <h2 class="titulo-links-escolha">Solicitações de transferência</h2>
      </div>
    </div>
    <div class="pm-card" v-if="acesso.visualizar">
      <pm-DataTable :value="listaAlunosTransferidos"   dataKey="id" :rowHover="true" filterDisplay="menu" v-model:filters="filters"
        :globalFilterFields="['nome']" responsiveLayout="scroll">
        <template #header>

          <div class="grid">
            <div class="grid sm:col-12 md:col-3 lg:col-4 xl:col-4">
              <pm-InputText
                style="height: 34px !important; width: 100% !important;"
                type="text"
                v-model="fullName"
                @keyup.enter="listarTudo(this.selectedOption)"
                placeholder="Nome Completo"
              />
            </div>
            <div class="grid sm:col-12 md:col-4 lg:col-4 xl:col-3">
              <div class="field" style="padding-left: 10px;">
                <label style="font-weight: 400 !important;">Escolha o status de transferência: </label>
                <select class="p-inputtext p-component" v-model="selectedOption" @change="listarTudo($event.target.value)" >
                  <option value="2">Todas Solicitações</option>
                  <option value="0">Em aberto</option>
                  <option value="1">Encerradas</option>
                </select>
              </div>
            </div>
          </div>
        </template>


        <pm-Column field="nome" header="NOME DO ALUNO" sortable style="font-size: 11px;vertical-align:middle !important">
          <template #body="{ data }">
            {{ data.info.aluno.nome }}   {{ data.info.aluno.sobrenome }}
          </template>
        </pm-Column>

        <pm-Column field="tipo_evento" header="TIPO DE EVENTO" style="font-size: 11px;vertical-align:middle !important">
        </pm-Column>

        <pm-Column field="escola_atual" header="ESCOLA ANTIGA" sortable style="font-size: 11px;vertical-align:middle !important">
          <template #body="{ data }">
            <p>
              {{ data.info.escolaAtual.nome }}
            </p>
          </template>
        </pm-Column>

        <pm-Column field="segmento" header="SEGMENTO ANTIGO" sortable style="font-size: 11px;vertical-align:middle !important">
          <template #body="{ data }">
            <p>
              {{ data.info.segmento.nome }}
            </p>
          </template>
        </pm-Column>

        <pm-Column field="serie" header="SÉRIE ANTIGA" sortable style="font-size: 11px;vertical-align:middle !important">
          <template #body="{ data }">
            <p>
              {{ data.info.serie.nome }}
            </p>
          </template>
        </pm-Column>



        <pm-Column field="turma_atual" header="TURMA ANTIGA" sortable style="font-size: 11px;vertical-align:middle !important">
          <template #body="{ data }">
            <p>
              {{ data.info.turmaAtual.nome }}
            </p>
          </template>
        </pm-Column>


        <pm-Column field="turma_destino" header="ESCOLA DESTINO" sortable style="font-size: 11px;vertical-align:middle !important">
          <template #body="{ data }">
            <p v-if="data.info.escolaDestino != '' && data.info.escolaDestino != null">
              {{ data.info.escolaDestino.nome }}
            </p>
            <p v-else>
              -
            </p>
          </template>
        </pm-Column>

        <pm-Column field="turma_destino" header="TURMA DESTINO" sortable style="font-size: 11px;vertical-align:middle !important">
          <template #body="{ data }">
            <p v-if="data.info.turmaDestino != '' && data.info.turmaDestino != null">
              {{ data.info.turmaDestino.nome }}
            </p>
            <p v-else>
              -
            </p>
          </template>
        </pm-Column>
        <pm-Column  field="data_evento" header="DATA DE EVENTO"  style="font-size: 11px;vertical-align:middle !important">
          <template #body="{ data }">
            <p>
              {{ data.data_de_transferencia_br }}
            </p>
          </template>
        </pm-Column>
        <pm-Column field="status" header="Status"  style="">
          <template #body="{ data }">
            <p v-if="data.flag_transferido" style="">
              Concluído
            </p>
            <div v-else style="" >
              <pm-Button :disabled="situacaoAno == 0" v-if="!data.flag_transferido" type="button" @click="openModal(data)" icon="pi pi-check"
                title="Verificar dados e realizar transferência" class="p-button-sm mr-1"
              ></pm-Button>

              <pm-Button :disabled="situacaoAno == 0" v-if="!data.flag_transferido" title="Cancelar Solicitação de transferência" type="button"
                @click="deletarSolicitacaoDeTransferencia(data.id)"
                icon="pi pi-times-circle" class="p-button-sm p-button-danger" ></pm-Button>
            </div>
          </template>
        </pm-Column>
      </pm-DataTable>
      <div class="p-paginator p-component p-paginator-bottom" >
        <div class="p-paginator-pages" style="display: inline-flex;">
          <div  v-for="n in links" :key="n">
            <button class="p-paginator-next p-paginator-element p-link" @click="paginas(n)" v-if="!n.active"
              style="border: 0px solid #ebebeb;border-radius: 0;">
              <p class="p-paginator-icon " v-html="n.label"  style="margin-bottom:0px !important;"></p>
            </button>
            <button class="p-paginator-page p-paginator-element p-link p-highlight" @click="paginas(n)" v-if="n.active" style="border-radius: 0;">{{n.label}}</button>
          </div>
        </div>
      </div>
    </div>
  </section>
  <pm-Dialog   v-if="!carregandoPagina" header="Transferência/Saída de aluno" v-model:visible="showModal" :style="{width: '30vw'}"  :modal="true"  after-hide="clearUser">
    <div class="p-fluid formgrid grid">
      <div class="field col-12 md:col-12">
        <label for="aluno">Nome do aluno: <strong>{{ alunoParaSerTransferido.info.aluno.nome }} {{ alunoParaSerTransferido.info.aluno.sobrenome }}</strong></label>
      </div>
      <div class="field col-12 md:col-4">
        <label for="nascimento">Data de evento:</label>
        <pm-InputText
          v-model="alunoParaSerTransferido.data_de_transferencia"
          type="date"
        />
      </div>
      <div class="field col-12 md:col-8">
        <label for="sangue">Evento:</label>
        <pm-Dropdown
          :disabled="alunoParaSerTransferido.tipo_evento == 'Transferência de rematriculados'"
          @change="buscarEscola"
          v-model="alunoParaSerTransferido.tipo_evento"
          :options="listaDeEventos"
          placeholder="Selecione o evento"
        />
      </div>
      <div class="field col-12 md:col-6" v-if="alunoParaSerTransferido.tipo_evento == 'Transferido Internamente' || alunoParaSerTransferido.tipo_evento == 'Reclassificado' || alunoParaSerTransferido.tipo_evento == 'Transferência de rematriculados'">
        <label for="sangue">Escola de destino:</label>
        <pm-Dropdown
          :disabled="alunoParaSerTransferido.tipo_evento == 'Transferência de rematriculados'"
          @change="buscarTurmaOuSegmento"
          optionLabel="nome"
          v-model="alunoParaSerTransferido.escola_destino"
          :options="listaDeEscolas"
          placeholder="Selecione a escola de destino do aluno"
        />
      </div>
      <div class="field col-12 md:col-6" v-if="alunoParaSerTransferido.tipo_evento == 'Reclassificado' && alunoParaSerTransferido.escola_destino != ''">
        <label for="sangue">Selecione o segmento:</label>
        <pm-Dropdown
          @change="listaSerie(alunoParaSerTransferido.segmento_destino)"
          optionLabel="nome"
          v-model="alunoParaSerTransferido.segmento_destino"
          :options="listaDeSegmentos"
          placeholder="Selecione a escola de destino do aluno"
        />
      </div>
      <div class="field col-12 md:col-6" v-if="alunoParaSerTransferido.tipo_evento == 'Reclassificado' && alunoParaSerTransferido.segmento_destino != ''">
        <label for="sangue">Selecione a série:</label>
        <pm-Dropdown
          @change="buscarTurmas()"
          optionLabel="nome"
          v-model="alunoParaSerTransferido.serie_destino"
          :options="listaDeSeries"
          placeholder="Selecione a escola de destino do aluno"
        />
      </div>
      <div class="field col-12 md:col-6" v-if="!listaDeTurmas.length && alunoParaSerTransferido.escola_destino != '' && alunoParaSerTransferido.tipo_evento == 'Transferido Internamente'">
        <h6>Não existem turmas na escola selecionada do mesmo nível da turma atual para realizar a transferência!</h6>
      </div>
      <div class="field col-12 md:col-6" v-if="listaDeTurmas.length">
        <label for="sangue">Turma de destino do aluno:</label>
        <pm-Dropdown
          @change="formatarOsDados"
          optionLabel="nome"
          v-model="alunoParaSerTransferido.info.turmaDestino"
          :options="listaDeTurmas"
          placeholder="Selecione a turma de destino do aluno"
        />
      </div>
      <div class="field col-12 md:col-3" v-if="alunoParaSerTransferido.tipo_evento == 'Reclassificado' && alunoParaSerTransferido.info.turmaDestino != ''">
        <label for="media">Média do aluno</label>
        <pm-InputText
          @change="formatarOsDados"
          optionLabel="nome"
          v-model="alunoParaSerTransferido.media"
          :options="listaDeTurmas"
          placeholder="Média do Aluno"
        />
      </div>
      <div class="field col-12 md:col-9" v-if="alunoParaSerTransferido.tipo_evento == 'Reclassificado' && alunoParaSerTransferido.media != ''">
        <label for="sangue">Selecione em quais períodos esta nota será aplicada:</label>
        <pm-Dropdown
          optionLabel="nome"
          v-model="alunoParaSerTransferido.peridosSelecionadosParaMedia"
          :options="listaMediaPeriodos"
          placeholder="Selecione a turma de destino do aluno"
        />
      </div>
      <div class="field col-12 md:col-4" v-if="alunoParaSerTransferido.tipo_evento == 'Reclassificado' && alunoParaSerTransferido.media != ''" style="padding-top: 38px;">
        <pm-Button  class="p-button-success" label="Histórico de presenças"  icon="pi pi-calendar" @click="buscarHistorioDePresencasAlunoTurma" autofocus />
      </div>
      <div class="field col-12 md:col-4" v-if="alunoParaSerTransferido.tipo_evento == 'Reclassificado' && alunoParaSerTransferido.media != ''">
        <label for="media">Adicionar total de presenças</label>
        <pm-InputText
          @change="formatarOsDados"
          optionLabel="nome"
          v-model="alunoParaSerTransferido.presencas"
          :options="listaDeTurmas"
          placeholder="Média de presença do aluno"
        />
      </div>
      <div class="field col-12 md:col-4" v-if="alunoParaSerTransferido.tipo_evento == 'Reclassificado' && alunoParaSerTransferido.media != ''">
        <label for="media">Adicionar total de aulas dadas</label>
        <pm-InputText

          @change="formatarOsDados"
          optionLabel="nome"
          v-model="alunoParaSerTransferido.presencasLancadas"
          :options="listaDeTurmas"
          placeholder="Média aulas dadas"
          inputmode="numeric"
        />
      </div>
    </div>
    <!-- </div> -->

    <template #footer>
      {{ alunoParaSerTransferido.turmaDestino }}
      <pm-Button  v-if="['Transferido Externamente', 'Falecido', 'Evadido'].includes(alunoParaSerTransferido.tipo_evento)" label="Aprovar" @click="realizarTransferencia" autofocus />
      <pm-Button  v-if="['Transferido Internamente'].includes(alunoParaSerTransferido.tipo_evento) && alunoParaSerTransferido.info.turmaDestino != '' && alunoParaSerTransferido.tipo_evento != undefined" label="Aprovar" @click="realizarTransferencia" autofocus />
      <pm-Button  v-if="['Reclassificado'].includes(alunoParaSerTransferido.tipo_evento) && alunoParaSerTransferido.info.turmaDestino != '' && alunoParaSerTransferido.tipo_evento != undefined && alunoParaSerTransferido.media != '' && alunoParaSerTransferido.peridosSelecionadosParaMedia != ''" label="Aprovar" @click="realizarTransferencia" autofocus />
      <pm-Button  v-if="['Transferência de rematriculados'].includes(alunoParaSerTransferido.tipo_evento) && alunoParaSerTransferido.info.escolaDestino != '' && alunoParaSerTransferido.tipo_evento != undefined" label="Aprovar" @click="realizarTransferencia" autofocus />
    </template>
  </pm-Dialog>

  <pm-Dialog header="Histórico de presenças"  v-model:visible="modalHistoricoDePresenca" position="right" :style="{ width: '50vw' }" :breakpoints="{ '960px': '75vw', '641px': '100vw' }">
    <div class="p-fluid formgrid grid">
      <div class="field col-12 md:col-12">
        <label for="aluno">Nome do aluno: <strong>{{ alunoParaSerTransferido.info.aluno.nome }} {{ alunoParaSerTransferido.info.aluno.sobrenome }}</strong></label>
      </div>
      <div class="field col-12 md:col-4">
        <label for="sangue">Selecione uma disciplina:</label>
        <pm-Dropdown
          @change="buscarHistorioDePresencas"
          v-model="historioTurmaDados.disciplina"
          :options="disciplinaALL"
          optionLabel="nome"
          placeholder="Selecione a disciplina"
        />
      </div>


    </div>

    <div  v-if="historicoDePresencas.info.turmasInfo.length">
      <pm-DataTable
        :value="historicoDePresencas.info.turmasInfo"
        filterDisplay="menu"
        responsiveLayout="scroll"
        :rows="40"
      >

        <pm-Column
          field="escola"
          header="Escola"
          style="font-size: 14px; vertical-align: middle !important"
        >
          <template #body="{ data }">
            <p>
              {{data.escolaNome.nome}}
            </p>
          </template>
        </pm-Column>

        <pm-Column
          field="serie"
          header="Série"
          style="font-size: 14px; vertical-align: middle !important"
        >
          <template #body="{ data }">
            <p>
              {{data.serieNome.nome}}
            </p>
          </template>
        </pm-Column>

        <pm-Column
          field="turma"
          header="Turma"
          style="font-size: 14px; vertical-align: middle !important"
        >
          <template #body="{ data }">
            <p>
              {{data.turmaNome}}
            </p>
          </template>
        </pm-Column>

        <pm-Column
          field="total_presenca"
          header="Total De Presenças do aluno"
          style="font-size: 14px; vertical-align: middle !important"
        >
          <template #body="{ data }">
            <p>{{data.total_presencas + data.total_presencas_manual}}</p>
          </template>
        </pm-Column>

        <pm-Column
          field="presencas_lancadas"
          header="Total De Aulas Lançadas"
          style="font-size: 14px; vertical-align: middle !important"
        >
          <template #body="{ data }">
            <p>{{data.total_lancadas + data.total_lancadas_manual}}</p>
          </template>
        </pm-Column>


        <pm-Column
          field="percentual"
          header="Porcentagem"
          style="font-size: 14px; vertical-align: middle !important"
        >
          <template #body="{ data }">
            <p v-if="data.total_lancadas !== 0 || data.total_lancadas_manual !== 0">
              {{ Math.round(((data.total_presencas + data.total_presencas_manual) / (data.total_lancadas + data.total_lancadas_manual)) * 100) }}%
            </p>
          </template>
        </pm-Column>
      </pm-DataTable>
      <br><br>
      <div style="float: right;">
        <p>
        <h6>Presenças Totais: {{historicoDePresencas.info.total_presencas_geral}}</h6>
        <h6>Aulas Lançadas Totais: {{historicoDePresencas.info.total_lancadas_geral}}</h6>
        <h6 v-if="historicoDePresencas.info.total_lancadas_geral !== 0 || historicoDePresencas.info.total_lancadas_manual_geral !== 0">Porcentagem Total: {{ Math.round(((historicoDePresencas.info.total_presencas_geral + historicoDePresencas.info.total_presencas_manual_geral) / (historicoDePresencas.info.total_lancadas_geral + historicoDePresencas.info.total_lancadas_manual_geral)) * 100) }}%</h6>
        </p>
      </div>
    </div>
  </pm-Dialog>
</template>

<script>
import { defineComponent } from "vue";
import { Transferecia } from "@/class/transferencia.js";
import { Escola } from "@/class/escolas";
import { Turma } from "@/class/turma.js";
import { SegmentoEscolar } from "@/class/segmentoEscolar.js";
import { Presenca } from "@/class/presenca.js";
import HistorioDePresencas from '@/components/presenca/historicoDePresencas.vue'
import { DisciplinaEscolar } from "@/class/disciplina.js";

import axios from 'axios'


export default defineComponent({
  data() {
    const columns = [
      { key: "nome", label: "Nome", sortable: true },
      { key: "id", label: "Ação" },
    ];

    return {
      home: {icon: 'pi pi-home', to: '#'},
      items_bread: [
        {label: 'Solicitações de transferência'},
      ],
      filter: '',
      situacaoAno:1,
      links:[],

      columns,
      perPage: 20,
      currentPage: 1,
      acesso : {
        visualizar:1,
        cadastrar:1,
        editar:1,
        deletar:1,
      },
      listaAlunosTransferidos: [],
      showModal:false,
      alunoParaSerTransferido:{},
      listaDeEventos: ["Transferido Internamente","Transferência de rematriculados", "Transferido Externamente", "Evadido", "Falecido", "Reclassificado"],
      listaMediaPeriodos : [
          {
            id: 1,
            nome: "Apenas no 1º período"
          },
          {
            id: 2,
            nome: "No 1º e 2º período"
          },
          {
            id: 3,
            nome: "No 1º, 2º e 3º período"
          },
          {
            id: 4,
            nome: "Em todos os períodos"
          }
      ],
      carregandoPagina:false,
      listaDeEscolas : [],
      listaDeTurmas: [],
      listaDeSegmentos: [],
      listaDeSeries:[],
      selectedOption: 2,
      modalHistoricoDePresenca:false,
      turmasDoAluno : [],
      historioTurmaDados: {
        turma: '',
        disciplina: ''
      },
      historicoDePresencas:{
        "info": {
          "turmasInfo": []
        }
      },
      showModalHistorioPresencas: false,
      mostrarDisciplinasDaTurma: false,
      disciplinasDaTurma: [],
      disciplinaALL: [],
      fullName: undefined
    };
  },
  computed: {
    pages() {
      if(this.perPage && this.perPage !== "0"){
        return Math.ceil(this.listaAlunosTransferidos.total / this.perPage)
      }else{
        return this.listaAlunosTransferidos.total;
      }
    },
  },
  methods: {
    dataFormatoBr() {
      for (let i = 0; i < this.listaAlunosTransferidos.length; i++) {
        if (this.listaAlunosTransferidos[i].data_de_transferencia == null) {
          this.listaAlunosTransferidos[i].data_de_transferencia_br = '-';
        }
        else {
          const dateParts = this.listaAlunosTransferidos[i].data_de_transferencia.split("-");
          const formattedDate = `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`;
          this.listaAlunosTransferidos[i].data_de_transferencia_br = formattedDate;
        }
      }
    },
    buscarHistorioDePresencas() {
      const payload = {
          turma_atual_id: this.historioTurmaDados.turma.id,
          aluno_id: this.alunoParaSerTransferido.aluno_id,
          disciplina_id:this.historioTurmaDados.disciplina.id
      }
      this.carregando = true;

      Presenca.buscarHistorioDePresencasDeAluno(payload).then((resp) => {
        this.historicoDePresencas = resp.data;
        this.historicoDePresencas.nome = items.nome;
        this.showModalHistorioPresencas = true;
      }).catch((err) => {

        }).finally(() => {
          this.carregando = false;
      });
    },
    buscarHistorioDePresencasAlunoTurma() {
      this.mostrarDisciplinasDaTurma = false;
      this.historicoDePresencas.info.turmasInfo = [];
      this.turmasDoAluno = [];
      this.historioTurmaDados.turma = '';
      this.modalHistoricoDePresenca = true;
    },
    addAlunoDeVoltaATurma(data) {
      if (window.confirm("Essa ação não pode ser desfeita e os dados não poderão ser alterados. Tem certeza de que deseja continuar?")) {
        this.carregandoPagina = true;
          Transferecia.addAlunoDeVoltaATurma(data).then((resp) => {
          this.$vaToast.init({
                message: resp.data.message,
                iconClass: 'fa-star-o',
                position: 'top-right',
                duration: 3000,
                color: 'success',
                fullWidth: false,
            });
            this.listarTudo();
          }).catch((err) => {
            this.$vaToast.init({
                message: err.response.data,
                iconClass: 'fa-star-o',
                position: 'top-right',
                duration: 3000,
                color: 'danger',
                fullWidth: false,
            });
          }).finally(() => {
            this.carregandoPagina = false;
          });
      }
    },
    limparCampos() {
      if (this.alunoParaSerTransferido.tipo_evento != "Transferência de rematriculados") {
        this.alunoParaSerTransferido.escola_destino =  ''

      }
      this.alunoParaSerTransferido.segmento_destino = '';
      this.alunoParaSerTransferido.serie_destino = '';
      if (this.alunoParaSerTransferido.info != undefined)
        {this.alunoParaSerTransferido.info.turmaDestino = '';}
      this.alunoParaSerTransferido.media = '';
      this.alunoParaSerTransferido.presencas = '';
      this.alunoParaSerTransferido.presencasLancadas = '';
      this.alunoParaSerTransferido.peridosSelecionadosParaMedia = '';
      this.listaDeEscolas = [];
      this.listaDeTurmas = [];
      this.listaDeSegmentos = [];
      this.listaDeSeries = [];
    },
    async buscarSegmentoALL() {
      const data = await SegmentoEscolar.obtemTodos();
      this.listaDeSegmentos = data.data;
    },
    async listaSerie(segmento) {
      const data = await SegmentoEscolar.listarSegmentosPorSerie(segmento.id);
      this.listaDeSeries = data.data;
    },
    buscarTurmaOuSegmento() {
      //se for internamente buscar so as turmas.
      if (this.alunoParaSerTransferido.tipo_evento == 'Transferido Internamente'){
        this.buscarTurmas();
      }else if(this.alunoParaSerTransferido.tipo_evento == 'Transferência de rematriculados'){
        this.buscarEscola();
      }else{
        this.buscarSegmentoALL();
      }
    },
    buscarTurmas() {
      let infoParaBuscarTurma = {};
      if (this.alunoParaSerTransferido.tipo_evento == 'Transferido Internamente') {
        infoParaBuscarTurma = {
            ano: this.alunoParaSerTransferido.info.turmaAtual.ano,
            escola_id:  this.alunoParaSerTransferido.escola_destino.id,
            segmento_id:  this.alunoParaSerTransferido.info.turmaAtual.segmento_id,
            serie_id:  this.alunoParaSerTransferido.info.turmaAtual.serie_id,
        }

      }
      else if (this.alunoParaSerTransferido.tipo_evento == 'Reclassificado') {
        infoParaBuscarTurma = {
            ano: this.alunoParaSerTransferido.info.turmaAtual.ano,
            escola_id:  this.alunoParaSerTransferido.escola_destino.id,
            segmento_id:  this.alunoParaSerTransferido.segmento_destino.id,
            serie_id:  this.alunoParaSerTransferido.serie_destino.id,
        }
      }

      Turma.turmasAvancada(infoParaBuscarTurma).then((resp) => {
        this.listaDeTurmas = resp.data;
        if (this.listaDeTurmas.length) {
          //se a escola for a mesma apagar a turma atual do aluno da lista de turmas para selecionadar
          if (this.alunoParaSerTransferido.escola_destino.id == this.alunoParaSerTransferido.info.turmaAtual.escola_id) {
            this.listaDeTurmas = this.listaDeTurmas.filter(obj => obj.id != this.alunoParaSerTransferido.info.turmaAtual.id);
          }
        }

      }).catch((err) => {
          this.$vaToast.init({
              message: err.response.data,
              iconClass: 'fa-star-o',
              position: 'top-right',
              duration: 3000,
              color: 'danger',
              fullWidth: false,
          });
      }).finally(() => {
          this.carregandoPagina = false;
      });
    },
    buscarEscola() {
      this.limparCampos();
      //so buscar escolas para transferencia interna ou reclassificacao ou rematriculado
      if (this.alunoParaSerTransferido.tipo_evento != 'Transferido Internamente' && this.alunoParaSerTransferido.tipo_evento != 'Reclassificado' && this.alunoParaSerTransferido.tipo_evento != 'Transferência de rematriculados') {
        this.listaDeEscolas = [];
        return false;
      }
      this.carregandoPagina = true;
      Escola.obtemTodos(this.alunoParaSerTransferido).then((resp) => {
        this.listaDeEscolas = resp.data;
      }).catch((err) => {
          this.$vaToast.init({
              message: err.response.data,
              iconClass: 'fa-star-o',
              position: 'top-right',
              duration: 3000,
              color: 'danger',
              fullWidth: false,
          });
        }).finally(() => {
          this.carregandoPagina = false;
        });
    },
    realizarTransferencia() {
      if (window.confirm("Tem certeza de que deseja continuar com a transfência/saída do aluno da turma e/ou escola?")) {
        this.alunoParaSerTransferido.flag_transferido = 1;
        if (this.alunoParaSerTransferido.tipo_evento == 'Transferido Internamente' || this.alunoParaSerTransferido.tipo_evento == 'Reclassificado'){
          this.alunoParaSerTransferido.turma_destino = this.alunoParaSerTransferido.info.turmaDestino.id;
        }
        this.carregandoPagina = true;
        Transferecia.alterar(this.alunoParaSerTransferido).then((resp) => {
        this.$vaToast.init({
              message: resp.data.message,
              iconClass: 'fa-star-o',
              position: 'top-right',
              duration: 3000,
              color: 'success',
              fullWidth: false,
          });
          this.listarTudo();
        }).catch((err) => {
          this.$vaToast.init({
              message: err.response.data,
              iconClass: 'fa-star-o',
              position: 'top-right',
              duration: 3000,
              color: 'danger',
              fullWidth: false,
          });
        }).finally(() => {
          this.carregandoPagina = false;
          this.showModal = !this.showModal;
        });
      }
    },
    openModal(data) {
      this.alunoParaSerTransferido = data;

      //para o aluno que é rematricula
      if (this.alunoParaSerTransferido.tipo_evento === "Transferência de rematriculados") {
        this.alunoParaSerTransferido.escola_destino = data.info.escolaDestino;
        console.log('this',this.alunoParaSerTransferido.escola_destino);
        console.log('this2',this.alunoParaSerTransferido.info.escolaDestino);
      }

      console.log("al",this.alunoParaSerTransferido);
      //selecionar a data atual
      const currentDate = new Date();
      const formattedDate = currentDate.toISOString().slice(0, 10);
      this.alunoParaSerTransferido.data_de_transferencia = formattedDate;
      this.buscarEscola();
      this.showModal = !this.showModal;
    },

    async paginas(n){
      if(n.url != null){
        if(!n.active){
          const res = await axios.get(n.url);
          this.listaAlunosTransferidos =[];
          this.listaAlunosTransferidos = Object.values(res.data.data);
          this.dataFormatoBr();
          this.links = res.data.links;
        }
      }
    },

    deletarSolicitacaoDeTransferencia(id){
        if (window.confirm("Você realmente deseja excluir a solicitação de transferência?")) {
          this.carregandoPagina = true;
          Transferecia.remover(id).then((resp) => {
            this.$vaToast.init({
                  message: resp.data.message,
                  iconClass: 'fa-star-o',
                  position: 'top-right',
                  duration: 3000,
                  color: 'success',
                  fullWidth: false,
              });
              this.listarTudo();
            }).catch((err) => {
              this.$vaToast.init({
                  message: err.response.data.error,
                  iconClass: 'fa-star-o',
                  position: 'top-right',
                  duration: 3000,
                  color: 'danger',
                  fullWidth: false,
              });
            }).finally(() => {
              this.carregandoPagina = false;
          });
      }
    },
    async listarTudo(tipo = "2") {
      //tipo 2(busca tudo)
      //tipo 1(busca as fechadas)
      //tipo 0 (busca as abertas)
      this.carregandoPagina = true;
      Transferecia.obtemTodos(tipo, this.fullName).then((resp) => {
          this.listaAlunosTransferidos = resp.data.data;
          this.dataFormatoBr();
          this.links = resp.data.links;
        }).catch((err) => {
          console.log(err);
        }).finally(() => {
          this.carregandoPagina = false;
      })
    },
    async listarDisciplinas() {
      this.carregandoPagina = true;
      DisciplinaEscolar.obtemTodos().then((resp) => {
          this.disciplinaALL = resp.data;
          this.disciplinaALL[0].nome = this.disciplinaALL[0].nome + " (FUNDAMENTAL I)"
        }).catch((err) => {
          console.log(err);
        }).finally(() => {
          this.carregandoPagina = false;
      })
    }
  },
  async beforeMount() {
    this.situacaoAno = parseInt(sessionStorage.getItem("anoSitucao"));
    this.listarDisciplinas();
    this.limparCampos();
    this.listarTudo();
  },
});
</script>

<style lang="scss" scoped>
.table-example--pagination {
  padding-top: 20px;
  text-align: center;
  text-align: -webkit-center;
}
</style>
